$WHITE: #ffffff;
$WHITE_TRANSPARENT_05: rgba(255, 255, 255, 0.05);
$WHITE_TRANSPARENT_20: rgba(255, 255, 255, 0.2);
$WHITE_TRANSPARENT_40: rgba(255, 255, 255, 0.4);
$WHITE_TRANSPARENT_50: rgba(255, 255, 255, 0.5);
$WHITE_TRANSPARENT_60: rgba(255, 255, 255, 0.6);
$WHITE_TRANSPARENT_94: rgba(255, 255, 255, 0.94);
$BLACK: #000000;
$BLACK_TRANSPARENT_03: rgba(0, 0, 0, 0.03);
$BLACK_TRANSPARENT_05: rgba(0, 0, 0, 0.05);
$BLACK_TRANSPARENT_08: rgba(0, 0, 0, 0.08);
$BLACK_TRANSPARENT_10: rgba(0, 0, 0, 0.1);
$BLACK_TRANSPARENT_12: rgba(0, 0, 0, 0.12);
$BLACK_TRANSPARENT_16: rgba(0, 0, 0, 0.16);
$BLACK_TRANSPARENT_20: rgba(0, 0, 0, 0.2);
$BLACK_TRANSPARENT_25: rgba(0, 0, 0, 0.25);
$BLACK_TRANSPARENT_40: rgba(0, 0, 0, 0.4);
$BLACK_TRANSPARENT_42: rgba(0, 0, 0, 0.42);
$BLACK_TRANSPARENT_60: rgba(0, 0, 0, 0.6);
$BLACK_TRANSPARENT_80: rgba(0, 0, 0, 0.8);
$BLACK_TRANSPARENT_90: rgba(0, 0, 0, 0.9);

// PRIMARY COLORS
$PRIMARY_COLOR: var(--primary-color, #5d21d1);
$PRIMARY_COLOR_RGB: var(--primary-color-rgb, 93, 33, 209);
$PRIMARY_TRANSPARENT_015: rgba($PRIMARY_COLOR_RGB, 0.015);
$PRIMARY_TRANSPARENT_02: rgba($PRIMARY_COLOR_RGB, 0.02);
$PRIMARY_TRANSPARENT_03: rgba($PRIMARY_COLOR_RGB, 0.03);
$PRIMARY_TRANSPARENT_05: rgba($PRIMARY_COLOR_RGB, 0.05);
$PRIMARY_TRANSPARENT_08: rgba($PRIMARY_COLOR_RGB, 0.08);
$PRIMARY_TRANSPARENT_10: rgba($PRIMARY_COLOR_RGB, 0.1);
$PRIMARY_TRANSPARENT_12: rgba($PRIMARY_COLOR_RGB, 0.12);
$PRIMARY_TRANSPARENT_17: rgba($PRIMARY_COLOR_RGB, 0.17);
$PRIMARY_TRANSPARENT_20: rgba($PRIMARY_COLOR_RGB, 0.2);
$PRIMARY_TRANSPARENT_25: rgba($PRIMARY_COLOR_RGB, 0.25);
$PRIMARY_TRANSPARENT_40: rgba($PRIMARY_COLOR_RGB, 0.4);
$PRIMARY_TRANSPARENT_50: rgba($PRIMARY_COLOR_RGB, 0.5);
$PRIMARY_TRANSPARENT_60: rgba($PRIMARY_COLOR_RGB, 0.6);
$PRIMARY_TRANSPARENT_65: rgba($PRIMARY_COLOR_RGB, 0.65);
$PRIMARY_TRANSPARENT_70: rgba($PRIMARY_COLOR_RGB, 0.7);

// SECONDARY COLORS
$SECONDARY_COLOR: var(--secondary-color, #37deb6);
$SECONDARY_COLOR_RGB: var(--secondary-color-rgb, 55, 222, 182);
$SECONDARY_TRANSPARENT_015: rgba($SECONDARY_COLOR_RGB, 0.015);
$SECONDARY_TRANSPARENT_01: rgba($SECONDARY_COLOR_RGB, 0.01);
$SECONDARY_TRANSPARENT_07: rgba($SECONDARY_COLOR_RGB, 0.07);
$SECONDARY_TRANSPARENT_10: rgba($SECONDARY_COLOR_RGB, 0.1);
$SECONDARY_TRANSPARENT_20: rgba($SECONDARY_COLOR_RGB, 0.2);
$SECONDARY_TRANSPARENT_70: rgba($SECONDARY_COLOR_RGB, 0.7);
$SECONDARY_TRANSPARENT_86: rgba($SECONDARY_COLOR_RGB, 0.86);

// TERTIARY COLORS
$TERTIARY_COLOR: var(--tertiary-color, #ace7ff);
$TERTIARY_COLOR_RGB: var(--tertiary-color-rgb, 172, 231, 255);

// TYPOGRAPHY COLORS
$PRIMARY_TEXT: #404040;
$PRIMARY_TEXT_TRANSPARENT_03: rgba(64, 64, 64, 0.03);
$PRIMARY_TEXT_TRANSPARENT_04: rgba(64, 64, 64, 0.04);
$PRIMARY_TEXT_TRANSPARENT_05: rgba(64, 64, 64, 0.05);
$PRIMARY_TEXT_TRANSPARENT_06: rgba(64, 64, 64, 0.6);
$PRIMARY_TEXT_TRANSPARENT_10: rgba(64, 64, 64, 0.1);
$PRIMARY_TEXT_TRANSPARENT_30: rgba(64, 64, 64, 0.3);
$PRIMARY_TEXT_TRANSPARENT_60: rgba(64, 64, 64, 0.6);
$PRIMARY_TEXT_TRANSPARENT_80: rgba(64, 64, 64, 0.8);
$BODY_TEXT: #666666;
$HELPER_TEXT: #999999;
$HYPERLINK: #0a71d1;

// THEME COLORS
$BG_GREY: #f9f9f9;
$BG_LIGHT: #fcfcfc;
$DIVIDER: #cecece;
$DIVIDER_DARK: #cccccc;
$BORDER: #dedede;
$ICON_COLOR: #666666;
$ICON_BG: #f8f8f8;
$TEXT_BTN: #5522d0;
$HOVER: #f9f9f9;
$ILLUSTRATION_COLOR: #222222;
$SOLITUDE: #e5e8ec;

// STATES COLORS
$SUCCESS: #0b8626;
$SUCCESS_TRANSPARENT_12: rgba(11, 134, 38, 0.12);
$WARNING: #ed9511;
$WARNING_TRANSPARENT_12: rgba(237, 149, 17, 0.12);
$ERROR: #e01e1e;
$ERROR_TRANSPARENT_12: rgba(224, 30, 30, 0.12);
$INFO: #1a90ff;
$INFO_TRANSPARENT_12: rgba(26, 144, 255, 0.12);
$DISABLED: #9a9a9a;
$ERROR_TEXT: #5f2120;
$ERROR_ICON: #ef5350;
$DISABLED: #9a9a9a;
$PRIMARY_ACTIVE: #5d21d1;
$PRIMARY_ACTIVE_TRANSPARENT_12: rgb(93, 33, 209, 0.12);
$DISABLED_TRANSPARENT_12: rgba(154, 154, 154, 0.12);
$DISABLED_BACKGROUND: #f3f3f3;

// NEW COLORS
$MOON_GREY: #f2edfb;
$PALE_GAMBOGE: #fdf2e2;
$BUTTON_TEXT: #333333;
$ANTIQUE_WHITE: #f9e4e4;
$TRANSPARENT: transparent;
$BLUE_LIGHT: #e5f6fd;
$BLUE_INFO_ICON: #03a9f4;
$BLUE_INFO_TEXT: #014361;
$PRECIOUS_PEARLS: #f0efee;
$WHITESMOKE: #f5f5f5;
$STAR_DUST: #9e9e9e;
$BRIDAL_HEATH: #f7ebdc;
$LOCHMARA: #2573af;
$SOLITUDE: #e5eef6;
$MISTY_ROSE: #fde6e4;
$CONCRETE: #f3f3f3;
$LAVANDER: #e5ddf7;
$WHISPER: #eeeeee;
$SELAGO: #f7f3fd;
$SKY_BLUE: #e6f0ff;

// OLD COLORS
$GREY_LIGHT_1_05: rgba(0, 29, 61, 0.05);
$WHITE_TRANSPARENT_25: rgba(207, 207, 207, 0.25);
$RED: rgba(220, 53, 68, 1);
$RED-PASTEL: #ffced7;
$RED_LIGHTER: #fdf2f4;
$RED_TRANSPARENT_20: rgba(238, 54, 54, 0.2);
$RED_TRANSPARENT_25: rgba(220, 53, 68, 0.25);
$YELLOW: #fdf3e8;
$YELLOW_LIGHT: #ffc632;
$YELLOW_DARK_TRANSPARENT_20: rgba(64, 64, 4, 0.2);
$YELLOW_DARK_1: #f1a027;
$YELLOW_2: rgba(255, 198, 50, 1);
$YELLOW_2_TRANSPARENT_10: rgba(255, 198, 50, 0.1);
$GREEN: #13721d;
$GREEN_LIGHT: #68d466;
$DARK_GREEN: #0b7522;
$GREEN_2: #139730;
$GREEN_2_LIGHT: #eef9ea;
$GREY: #616161;
$GREY_LIGHT: #e5e8ec;
$GREY_LIGHT_2: #f7f4fd;
$GREY_LIGHT_1: #f2f2f2;
$GREY_TRANSPARENT_1: #8f8f8f;
$GREY_TRANSPARENT_05: rgba(143, 143, 143, 0.05);
$CODE_GREY: #1c1c1c;
$GHOST_WHITE: #f7f7fc;
$MIDNIGHT_TRANSPARENT_01: rgba(0, 29, 61, 0.1);
$ALTO_COLOR: #dddddd;
$HONEY_DEW: #e6f8eb;
$BRIGHT-GRAY: #e5eef6;
$HAIL_STONE: #ececec;

// FONTS
$FONT_SIZE: 14;
$COMMON_BORDER_RADIUS: 6;
$HTML_FONT_SIZE: 16;

// Font size
$H1: 96;
$H2: 60;
$H3: 48;
$H4: 36;
$H5: 24;
$H6: 20;
$BODY_1: 16;
$BODY_2: 14;
$BODY_3: 13;
$SUBTITLE_1: 16;
$SUBTITLE_2: 14;
$SUBTITLE_3: 14;
$BUTTON: 14;
$BUTTON_SMALL: 12;
$CAPTION: 14;
$OVERLINE: 12;
$SMALL_TEXT: 12;
$TAGLINE: 10;
$MEDIUM_TEXT: 15;
// Font weight
$BOLD_FONT_WEIGHT: 700;
$SEMI_BOLD_FONT_WEIGHT: 600;
$MEDIUM_FONT_WEIGHT: 500;
$REGULAR_FONT_WEIGHT: 400;
$LIGHT_FONT_WEIGHT: 300;

//  Screen breakpoints
$X-LARGE-SCREEN-MAX: 1439px !default;
$X-LARGE-SCREEN-UP: 1231px !default;
$LARGE-SCREEN-MAX: 1230px !default;
$LARGE-SCREEN-UP: 1201px !default;
$LARGE-SCREEN: 1200px !default;
$XL-SCREEN: 1440px !default;
$MEDIUM-SCREEN-MAX: 1025px !default;
$MEDIUM-SCREEN-UP: 993px !default;
$MEDIUM-SCREEN: 992px !default;
$TABLET-SCREEN-UP: 769px !default;
$TABLET-SCREEN: 768px !default;
$TABLET-SCREEN-DOWN: 767px !default;
$SMALL-SCREEN-UP: 601px !default;
$SMALL-SCREEN: 600px !default;
$MOBILE-SCREEN: 480px !default;
$SMALL-MOBILE-SCREEN: 410px !default;
$EXTRA-SMALL-SCREEN: 320px !default;

$button-transform: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
$STEPPER_BUTTON_DISABLED: rgba(85, 34, 208, 0.05);
$Z_INDEX_100: 100;
$AUTO_COMPLETE_BOX_SHADOW: 0 2px 4px -1px #0003, 0 4px 5px #00000024,
  0 1px 10px #0000001f;
$GRID_BLACK: #26293e;
$BOX_SHADOW: rgba(222, 222, 222, 0.1);

$Z_INDEX_1: 1;

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
