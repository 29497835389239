@import 'src/styles/config/globals/imports';

.slide-dialog {
  @include flex-box($direction: column);
  width: 100%;
  height: 100%;

  &__wrapper {
    @include flex-box($direction: column);
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__body {
    flex: 1;
    overflow: hidden;
  }

  &__btn {
    position: absolute;
    height: 1.75rem;
    width: 1.75rem;
    top: 0.65rem;
    right: 0.65rem;
    z-index: $z-index-2;
    background: $quaternary-background;
    border-radius: 50%;
    &-icon.mat-icon {
      color: $primary-text-color;
      font-size: 1.25rem;
      line-height: 1.25rem;
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}
