@import './_variables';

// Use this mixin for multiline ellipsis.
@mixin text-ellipsis($no-of-lines: 2) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $no-of-lines;
  text-overflow: ellipsis;
}

@mixin a-link($color) {
  color: $color;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus,
  &:target {
    color: $color;
    text-decoration: underline;
  }
}

@mixin font-poppins-regular($fontSize: 1rem, $lineHeight: 1.5rem) {
  font-family: $poppins-regular !important;
  font-size: $fontSize !important;
  line-height: $lineHeight !important;
  font-weight: 400 !important;
}

@mixin font-poppins-medium($fontSize: 1rem, $lineHeight: 1.5rem) {
  font-family: $poppins-medium !important;
  font-size: $fontSize !important;
  line-height: $lineHeight !important;
  font-weight: 500 !important;
}

@mixin font-poppins-bold($fontSize: 1rem, $lineHeight: 1.5rem) {
  font-family: $poppins-bold !important;
  font-size: $fontSize !important;
  line-height: $lineHeight !important;
  font-weight: 600 !important;
}

@mixin font-light($fontSize, $lineHeight) {
  font-family: 'Red Hat Display';
  font-size: $fontSize;
  font-weight: 300;
  line-height: $lineHeight;
}

@mixin font-regular($fontSize, $lineHeight) {
  font-family: 'Red Hat Display';
  font-size: $fontSize;
  line-height: $lineHeight;
}

@mixin font-medium($fontSize, $lineHeight) {
  font-family: 'Red Hat Display';
  font-size: $fontSize;
  font-weight: 500;
  line-height: $lineHeight;
}

@mixin font-bold($fontSize, $lineHeight) {
  font-family: 'Red Hat Display';
  font-size: $fontSize;
  line-height: $lineHeight;
  font-weight: 700;
}

@mixin message-alert($color, $bgColor, $borderColor) {
  color: $color;
  background-color: $bgColor;
  border-color: $borderColor;
}

@mixin responsive-up($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}

@mixin responsive-down($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}

/* Breakpoint for desktop */
@mixin bp-desktop {
  @media screen and (min-width: $medium-screen) {
    @content;
  }
}

/* Breakpoint for large screens */
@mixin bp-desktop-large {
  @media screen and (min-width: $xl-screen) {
    @content;
  }
}

@mixin bp-min-tablet {
  @media screen and (min-width: $tablet-screen) {
    @content;
  }
}

/* Breakpoint for tablet */
@mixin bp-tablet {
  @media screen and (max-width: $tablet-screen) {
    @content;
  }
}

/* Breakpoint for Mobile */
@mixin bp-mobile {
  @media screen and (max-width: $mobile-screen) {
    @content;
  }
}

/*
*   @include multiLineEllipsis(1rem * 1.2, 2, red);
*/
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: $white) {
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  overflow: hidden;
  position: relative;
  word-break: break-word;
  text-align: justify;
  padding-right: 1em;
  &:before {
    content: '...';
    position: absolute;
    bottom: 0;
    right: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    background: $white;
  }
}

@mixin overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@mixin preserveNewLine {
  overflow-wrap: break-word;
  white-space: pre-line;
}

@mixin progress-spinner($gutterSpace: 1px, $spinnerSize: 1px, $loaderColor: $primary-color) {
  position: relative;
  &::before {
    content: '';
    background-image: conic-gradient(transparent 20deg, $loaderColor 360deg);
    border-radius: 50%;
    height: calc(100% + (#{$spinnerSize} * 2));
    width: calc(100% + (#{$spinnerSize} * 2));
    position: absolute;
    left: -($gutterSpace);
    top: -($gutterSpace);
    animation: rotate 2s infinite linear;
    z-index: -1;
  }

  &::after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: $white;
    border-radius: 50%;
    z-index: -1;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

// Mixin for media queries
// TODO: Replace with Responsive mixin from Interview Tools
@mixin media($mq-breakpoint, $mq-breakpoints: $breakpoints) {
  @if map-has-key($mq-breakpoints, $mq-breakpoint) {
    $mq-breakpoint: map-get($mq-breakpoints, $mq-breakpoint);
  }

  @media screen and (min-width: #{$mq-breakpoint}) {
    @content;
  }
}
