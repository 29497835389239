@import './variables';

@function getCoef() {
  @return calc($FONT-SIZE / 14);
}

@function getPxToRem($size) {
  @return (calc($size / $HTML-FONT-SIZE) * getCoef() * 1rem);
}

@mixin flex-box(
  $justifyContent: flex-start,
  $alignItems: stretch,
  $alignContent: stretch,
  $direction: row,
  $flexWrap: nowrap
) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems;
  align-content: $alignContent;
  flex-direction: $direction;
  flex-wrap: $flexWrap;
}

@mixin ellipsis($width: 100%) {
  width: $width;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// Use this mixin for multiline ellipsis.
@mixin text-ellipsis($no-of-lines: 2) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $no-of-lines;
  text-overflow: ellipsis;
}

/* Breakpoint for large screens */
@mixin bp-min-tablet {
  @media screen and (min-width: $TABLET-SCREEN-DOWN) {
    @content;
  }
}

@mixin grid(
  $rows: none,
  $columns: none,
  $row-gap: normal,
  $column-gap: normal
) {
  display: grid;
  row-gap: $row-gap;
  column-gap: $column-gap;
  grid-template-rows: $rows;
  grid-template-columns: $columns;
}
