$x-large-screen-max: 1439px !default;
$x-large-screen-up: 1231px !default;
$large-screen-max: 1230px !default;
$large-screen-up: 1201px !default;
$large-screen: 1200px !default;
$xl-screen: 1440px !default;
$medium-screen-max: 1025px !default;
$medium-screen-up: 993px !default;
$medium-screen: 992px !default;
$tablet-screen-up: 769px !default;
$tablet-screen: 768px !default;
$tablet-screen-down: 767px !default;
$small-screen-up: 601px !default;
$small-screen: 600px !default;
$mobile-screen: 480px !default;
$small-mobile-screen: 410px !default;
$extra-small-screen: 320px !default;

$breakpoints: (
  xxl: 1440px,
  xxxl: 1920px,
  'large-screen-max': 1230px,
  'large-screen-up': 1201px,
  'large-screen': 1200px,
  'medium-screen-max': 1025px,
  'medium-screen-up': 993px,
  'medium-screen': 992px,
  'tablet-screen-up': 769px,
  'tablet-screen': 768px,
  'tablet-screen-down': 767px,
  'small-screen-up': 601px,
  'small-screen': 600px,
  'mobile-screen': 480px,
  'small-mobile-screen': 410px,
  'extra-small-screen': 320px,
);

$browser-context: 16; // Default
$content-box-shadow: 0 10px 10px rgba(51, 51, 51, 0.16);
$text-color-light: #0000008a;

$desktop-width: 14.4;
$design-width: 14.4;
$tab-width: 10.24;
$mobile-width: 4.8;

$z-index-1: 1;
$z-index-2: 2;
$z-index-3: 3;
$z-index-4: 4;
$z-index-5: 5;
$z-index-100: 100;
$card-border-radius: 0.5rem;

// Black
$white: #ffffff;
$black: #333333;
$light-black: #505050;
$black-light: #3c4852;
$black-fade: #555;
$black-dark: #000000;
$black-dark-fade: #0000008a;
$black-transparent-20: rgba(0, 0, 0, 0.2);
$gray: #808080;
$light-grey: #f1f1f1;
$light-fade-grey: #f5f7fb;
$dark-grey: #e2e2e2;
$grey-dark: #444;
$grey-darker: #767676;
$light-gray: #fafafa;
$pale-grey: #e9eef2;
$slate-grey: #7a8b94;
$fade-grey: #c8c8c8b3;
$dusty-grey: #9a9a9a1f;
$tundora: #404040;
$alto: #dedede;
$davy-gray: #555;
$dove-gray: #666666;
$gray-color: #dddddd;
$alto-color: #dddddd;
$mercury-color: #ebebeb;
$grid-black: #26293e;
$bright-gray: #e5e8ec;
$concrete: #f3f3f3;
$white-smoke: #efefef;

$assessment-green-light: #eef9ea;
$assessment-green: #139730;
$assessment-yellow: #f1a027;
$assessment-yellow-light: #fdf3e8;
$assessment-purple-light: #d9bbe5;
$assessment-red-light: #f38c86;

// Red
$red: #ff0000;
$menu-text-black: #000000de;
$error-red: #e01e1e;
$red-tint: #ff4242ad;
$orange-button: #ff5722;
$failure-red: #ca0b00;
$light-red: #ffedf0;
$setup-modal-red: rgba(224, 30, 30);
$red-stepper-bg: #f8e2e1;

// Yellow
$yellow: #fde038;
$orange: #ffa500;
$waiting-yellow: #efd061;
$faded-yellow: #ffff9b;
$gold: #f5c100;

// Green
$green: #008000;
$bright-green: #00ff29;
$primary-green: #a8e42b;
$success-green: #4bb543;
$light-green: #e9f9ef;
$green-stepper-bg: #53d982;

// Blue
$cornflower-blue: #e5e5e5;
$light-blue: #00bcd41a;
$cyan-blue: #e5eef6;
$light-cyan-blue: #f0f4f7;
$purple: #800080;
$purple-heart: #5d21d1;
$tangarao: #001d3d;
$ghost-white: #f9f9f9;
$lochmara: #2573af;

// Global-colors
$transparent: transparent;
$primary-color: #5d21d1;
$primary-color-rgba: 93, 33, 209;
$secondary-color: #37deb6;
$secondary-color-rgba: 55, 222, 182;
$tertiary-color: #ace7ff;
$border-color: #cccccc;
$error-color: #721c24;
$error-bg-color: #f8d7da;
$error-border-color: #f5c6cb;
$default-message: #004085;
$default-bg-color: #cce5ff;
$default-border-color: #b8daff;
$success-message: #155724;
$success-bg-color: #d4edda;
$success-border-color: #c3e6cb;
$invalid-input: #f44336;
$main-heading: #444;
$text-color: #666;
$light-border-color: #f1f1f3;
$cancel-btn-color: #bdc2c9;
$resource-footer: #4d4f52;
$small-text: #9e9e9e;
$success: #00ff00;
$warning: #ffc100;
$success-color: #0a9a33;
$danger: #ff4141;
$disabled-bg-color: #cccccc;
$disabled-text-color: #666666;
$disabled-stepper-button: rgba(85, 34, 208, 0.05);
$code-highlight: #cae0e4;
$tertiary-bg-color: #eaeef2;
$page-background: #e8f7fb;
$start-time-color: #5dba47;
$end-time-color: #d0021b;
$modal-action-background: #ecf0f1;
$progress-bar-background: #d0d0d0;
$upload-wrapper-border: #979797;
$text-light-black: rgba($black, 0.7);
$hover-light-black: rgba($black, 0.05);
$shadow-light-black: rgba($black, 0.4);
$color-black-12: rgba($black-dark, 0.12);
$color-black-45: rgba($black, 0.45);
$wisteria: #ccabe5;
$border-light: #0000000d;
$link-color: #0a71d1;
$tooltip-border-color: #efe9fa;
$header-select-color: #ebebeb;
$primary-highlight-color: #e6e4fc;
$gallery: #f0f0f0;
$caribbean-green: #00d6a2;

// Livestream
$livestream-card-border-color: #e3e2e2;
$livestream-card-empty-color: #666;
$livestream-default-chatbox-header: #5d21d1b5;

// Color-coding
$color-coding-red: #e98084;
$color-coding-red-fade: #fbeeee;
$color-coding-orange: #ed9125;
$color-coding-orange-fade: #fdf2e0;
$color-coding-green: #67b461;
$color-coding-green-fade: #e7f4e6;

// assessment report
$report-primary: #1f5f9c;
$report-green: #87e800;
$report-light-blue: #5dbcd4;
$report-border: #d9e7f0;
$report-border-light: #b5b5b5;
$report-progress-color: #81afcd;
$report-label-color: #5b94bf;
$action-primary: #065f9d;

// user assessment action buttons
$action-invite-user: #1c86ce;
$action-mark-present: #4d73e2;
$action-dark-cyan: #008b8b;
$action-pause-test: #ffa500;

// Attachment
$border-grey: #c2c2c2;
$primary-color-fade: #d2f3f6;

// Impersonation
$light-yellow: #fffbe6;
$highlight-blue: #e0ffff;
$grey-fade: #f5f5f5;

// Candidate Verification
$icon-font-color-light: #999999;

// dynamic color palette
$theme-primary: (
  50: var(--theme-primary-50),
  100: var(--theme-primary-100),
  200: var(--theme-primary-200),
  300: var(--theme-primary-300),
  400: var(--theme-primary-400),
  500: var(--theme-primary-500),
  600: var(--theme-primary-600),
  700: var(--theme-primary-700),
  800: var(--theme-primary-800),
  900: var(--theme-primary-900),
  A100: var(--theme-primary-A100),
  A200: var(--theme-primary-A200),
  A400: var(--theme-primary-A400),
  A700: var(--theme-primary-A700),
  contrast: (
    50: var(--contrast-primary-50),
    100: var(--contrast-primary-100),
    200: var(--contrast-primary-200),
    300: var(--contrast-primary-300),
    400: var(--contrast-primary-400),
    500: var(--contrast-primary-50),
    600: var(--contrast-primary-600),
    700: var(--contrast-primary-700),
    800: var(--contrast-primary-800),
    900: var(--contrast-primary-900),
    A100: var(--contrast-primary-A100),
    A200: var(--contrast-primary-A200),
    A400: var(--contrast-primary-A400),
    A700: var(--contrast-primary-A700),
  ),
);

$theme-secondary: (
  50: var(--theme-secondary-50),
  100: var(--theme-secondary-100),
  200: var(--theme-secondary-200),
  300: var(--theme-secondary-300),
  400: var(--theme-secondary-400),
  500: var(--theme-secondary-500),
  600: var(--theme-secondary-600),
  700: var(--theme-secondary-700),
  800: var(--theme-secondary-800),
  900: var(--theme-secondary-900),
  A100: var(--theme-secondary-A100),
  A200: var(--theme-secondary-A200),
  A400: var(--theme-secondary-A400),
  A700: var(--theme-secondary-A700),
  contrast: (
    50: var(--contrast-secondary-50),
    100: var(--contrast-secondary-100),
    200: var(--contrast-secondary-200),
    300: var(--contrast-secondary-300),
    400: var(--contrast-secondary-400),
    500: var(--contrast-secondary-50),
    600: var(--contrast-secondary-600),
    700: var(--contrast-secondary-700),
    800: var(--contrast-secondary-800),
    900: var(--contrast-secondary-900),
    A100: var(--contrast-secondary-A100),
    A200: var(--contrast-secondary-A200),
    A400: var(--contrast-secondary-A400),
    A700: var(--contrast-secondary-A700),
  ),
);

// dynamic color variables
$theme-primary-50: var(--theme-primary-50);
$theme-primary-50-rgb: var(--theme-primary-50-RGB);
$theme-primary-100: var(--theme-primary-100);
$theme-primary-100-rgb: var(--theme-primary-100-RGB);
$theme-primary-200: var(--theme-primary-200);
$theme-primary-200-rgb: var(--theme-primary-200-RGB);
$theme-primary-color: var(--theme-primary-500);
$theme-primary-color-rgb: var(--theme-primary-500-RGB);
$theme-primary-dark-color: var(--theme-primary-600);
$theme-primary-dark-color-rgb: var(--theme-primary-600-RGB);
$theme-secondary-50: var(--theme-secondary-50);
$theme-secondary-50-rgb: var(--theme-secondary-50-RGB);
$theme-secondary-200: var(--theme-secondary-200);
$theme-secondary-200-rgb: var(--theme-secondary-200-RGB);
$theme-secondary-color: var(--theme-secondary-500);
$theme-secondary-color-rgb: var(--theme-secondary-500-RGB);
$theme-secondary-dark-color: var(--theme-secondary-600);
$theme-secondary-dark-color-rgb: var(--theme-secondary-600-RGB);
$theme-primary-contrast: var(--contrast-primary-500);
$theme-primary-contrast-rgb: var(--contrast-primary-500-RGB);
$theme-secondary-contrast: var(--contrast-secondary-500);
$theme-secondary-contrast-rgb: var(--contrast-secondary-500-RGB);
$primary-color: var(--theme-primary-500);
$primary-color-rgb: var(--theme-primary-500-RGB);
$secondary-color: var(--theme-secondary-500);
$secondary-color-rgb: var(--theme-secondary-500-RGB);
$tertiary-color: var(--theme-tertiary-500);
$tertiary-color-rgb: var(--theme-tertiary-500-RGB);

// Custom snackBar color constants
$snackbar-grey: rgba(#444444, 0.92);
$fade-blue: rgba($theme-primary-color-rgb, 0.7);

$theme-tertiary-50: #6edafc14;
$theme-tertiary-200: #6edafc3d;
$theme-tertiary: #6edafc;

// interview tools
$spacer: 1;
$hyperlink-main: #0a71d1;
$state-active-50: #0b86261f;
$state-active: #0b8626;
$state-active-dark: #275f38;
$state-active-dark-50: rgba($state-active-dark, 0.5);
$state-warning-50: #ed95111f;
$state-warning: #ed9511;
$state-error-50: #e01e1e1f;
$state-error: #e01e1e;
$state-closed-50: #9a9a9a1f;
$state-closed: #9a9a9a;
$state-indicator-50: #1a90ff1f;
$state-indicator: #1a90ff;
$fade-black: #262627;
$border-color-50: #f2f2f2;
$border-color-dark: #dedede;
$light-silver: #d7d7d7;
$primary-focus-color: #2e3441;
$gunmetal-dark: #263238;
$picton-blue: #3b99f0;
$moon-raker: #e5ddf7;

$primary-background: var(--primary-background);
$secondary-background: var(--secondary-background);
$secondary-dark-background: var(--secondary-dark-background);
$secondary-light-background: var(--secondary-light-background);
$tertiary-background: var(--tertiary-background);
$tertiary-dark-background: var(--tertiary-dark-background);
$tertiary-light-background: var(--tertiary-light-background);
$quaternary-background: var(--quaternary-background);
$primary-text-color: var(--primary-text-color);
$secondary-text-color: var(--secondary-text-color);
$tertiary-text-color: var(--tertiary-text-color);
$icon-font-color: var(--icon-font-color);
$icon-background: var(--icon-background);
$border-light-color: var(--border-light-color);
$scrollbar-thumb-color: var(--scrollbar-thumb-color);
$scrollbar-active-color: var(--scrollbar-active-color);
$scrollbar-area-color: var(--scrollbar-area-color);
$background-fade-color: var(--background-fade-color);
$tooltip-text: var(--tooltip-text);
$tooltip-border: var(--tooltip-border);
$tooltip-shadow-color: var(--tooltip-shadow-color);
$primary-background-rgb: var(--primary-background-RGB);
$secondary-background-rgb: var(--secondary-background-RGB);
$secondary-dark-background-rgb: var(--secondary-dark-background-RGB);
$secondary-light-background-rgb: var(--secondary-light-background-RGB);
$tertiary-background-rgb: var(--tertiary-background-RGB);
$tertiary-dark-background-rgb: var(--tertiary-dark-background-RGB);
$quaternary-background-rgb: var(--quaternary-background-RGB);
$primary-text-color-rgb: var(--primary-text-color-RGB);
$secondary-text-color-rgb: var(--secondary-text-color-RGB);
$tertiary-text-color-rgb: var(--tertiary-text-color-RGB);
$icon-font-color-rgb: var(--icon-font-color-RGB);
$icon-background-rgb: var(--icon-background-RGB);
$border-light-color-rgb: var(--border-light-color-RGB);
$scrollbar-thumb-color-rgb: var(--scrollbar-thumb-color-RGB);
$scrollbar-active-color-rgb: var(--scrollbar-active-color-RGB);
$scrollbar-area-color-rgb: var(--scrollbar-area-color-RGB);
$background-fade-color-rgb: var(--background-fade-color-RGB);
$tooltip-text-rgb: var(--tooltip-text-RGB);
$tooltip-border-rgb: var(--tooltip-border-RGB);
$tooltip-shadow-color-rgb: var(--tooltip-shadow-color-RGB);

// Interview rating colors
// strong-yes
$jet-stream: #afddbd;
$iguana-green: #61be7d;
$forest-green: #28882c;
$mughal-green: #275f38;
// yes
$alabaster: #dcf0dd;
$celadon: #b5dfb7;
$apple: #4caf50;
// neutral
$diamond: #d3ebff;
$light-sky-blue: #94cdff;
$azure: #40a5fd;
// may-be
$almond: #fee2b9;
$caramel: #ffd699;
$vivid-gamboge: #ff9800;
// no
$seashell: #fcdddb;
$light-red: #fccbc7;
$coral-red: #f44336;

// dynamic color palette
$theme-primary: (
  50: var(--theme-primary-50),
  100: var(--theme-primary-100),
  200: var(--theme-primary-200),
  300: var(--theme-primary-300),
  400: var(--theme-primary-400),
  500: var(--theme-primary-500),
  600: var(--theme-primary-600),
  700: var(--theme-primary-700),
  800: var(--theme-primary-800),
  900: var(--theme-primary-900),
  A100: var(--theme-primary-A100),
  A200: var(--theme-primary-A200),
  A400: var(--theme-primary-A400),
  A700: var(--theme-primary-A700),
  contrast: (
    50: var(--contrast-primary-50),
    100: var(--contrast-primary-100),
    200: var(--contrast-primary-200),
    300: var(--contrast-primary-300),
    400: var(--contrast-primary-400),
    500: var(--contrast-primary-50),
    600: var(--contrast-primary-600),
    700: var(--contrast-primary-700),
    800: var(--contrast-primary-800),
    900: var(--contrast-primary-900),
    A100: var(--contrast-primary-A100),
    A200: var(--contrast-primary-A200),
    A400: var(--contrast-primary-A400),
    A700: var(--contrast-primary-A700),
  ),
);

$theme-secondary: (
  50: var(--theme-secondary-50),
  100: var(--theme-secondary-100),
  200: var(--theme-secondary-200),
  300: var(--theme-secondary-300),
  400: var(--theme-secondary-400),
  500: var(--theme-secondary-500),
  600: var(--theme-secondary-600),
  700: var(--theme-secondary-700),
  800: var(--theme-secondary-800),
  900: var(--theme-secondary-900),
  A100: var(--theme-secondary-A100),
  A200: var(--theme-secondary-A200),
  A400: var(--theme-secondary-A400),
  A700: var(--theme-secondary-A700),
  contrast: (
    50: var(--contrast-secondary-50),
    100: var(--contrast-secondary-100),
    200: var(--contrast-secondary-200),
    300: var(--contrast-secondary-300),
    400: var(--contrast-secondary-400),
    500: var(--contrast-secondary-50),
    600: var(--contrast-secondary-600),
    700: var(--contrast-secondary-700),
    800: var(--contrast-secondary-800),
    900: var(--contrast-secondary-900),
    A100: var(--contrast-secondary-A100),
    A200: var(--contrast-secondary-A200),
    A400: var(--contrast-secondary-A400),
    A700: var(--contrast-secondary-A700),
  ),
);

// dynamic color variables
$theme-primary-50: var(--theme-primary-50);
$theme-primary-100: var(--theme-primary-100);
$theme-primary-200: var(--theme-primary-200);
$theme-primary-color: var(--theme-primary-500);
$theme-primary-dark-color: var(--theme-primary-600);
$theme-secondary-50: var(--theme-secondary-50);
$theme-secondary-200: var(--theme-secondary-200);
$theme-secondary-color: var(--theme-secondary-500);
$theme-secondary-dark-color: var(--theme-secondary-600);
$theme-primary-contrast: var(--contrast-primary-500);
$theme-secondary-contrast: var(--contrast-secondary-500);

$theme-tertiary-50: #6edafc14;
$theme-tertiary-200: #6edafc3d;
$theme-tertiary: #6edafc;

// interview tools
$spacer: 1;
$hyperlink-main: #0a71d1;
$state-active-50: #0b86261f;
$state-active: #0b8626;
$state-warning-50: #ed95111f;
$state-warning: #ed9511;
$state-error-50: #e01e1e1f;
$state-error: #e01e1e;
$state-closed-50: #9a9a9a1f;
$state-closed: #9a9a9a;
$state-indicator-50: #1a90ff1f;
$state-indicator: #1a90ff;
$fade-black: #262627;
$border-color-50: #f2f2f2;
$border-color-dark: #dedede;
$light-silver: #d7d7d7;
$primary-focus-color: #2e3441;
$gunmetal-dark: #263238;
$picton-blue: #3b99f0;

$primary-background: var(--primary-background);
$secondary-background: var(--secondary-background);
$secondary-dark-background: var(--secondary-dark-background);
$secondary-light-background: var(--secondary-light-background);
$tertiary-background: var(--tertiary-background);
$tertiary-dark-background: var(---tertiary-dark-background);
$primary-text-color: var(--primary-text-color);
$secondary-text-color: var(--secondary-text-color);
$icon-font-color: var(--icon-font-color);
$icon-background: var(--icon-background);
$border-light-color: var(--border-light-color);
$scrollbar-thumb-color: var(--scrollbar-thumb-color);
$scrollbar-active-color: var(--scrollbar-active-color);
$scrollbar-area-color: var(--scrollbar-area-color);
$background-fade-color: var(--background-fade-color);

// Box-Shadows
$header-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05), 0 2px 10px 0 rgba(0, 0, 0, 0.05);
$question-part: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
$login-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
$btn-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
$light-black-shadow: 0 0 30px $shadow-light-black;
$card-shadow: 0px 0 5px 0 $shadow-light-black;
$snackbar-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
$fixed-btn-wrapper-shadow: 0px -4px 19px rgba($tundora, 0.2);

// fonts
$poppins-regular: 'Poppins-Regular';
$poppins-medium: 'Poppins-Medium';
$poppins-bold: 'Poppins-Bold';

// Remove when merging into master, as master contains these variables
$tundora: #404040;
$tooltip-border-color: #efe9fa;
$light-card-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
$light-card-shadow-top: 0 -1px 1px rgba(0, 0, 0, 0.05);
$soft-card-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
$pod-logo-shadow: 0 -5px 5px -5px rgb(0 0 0 / 16%);
$stepper-shadow: rgba($black-dark, 0.25) 0px 0.25rem 1.25rem;

// Borders
$light-card-border: 1px solid rgba(0, 0, 0, 0.12);
$nav-border: 1px solid rgba(0, 0, 0, 0.08);
$border-color: #d3d3d3; // Use for all revamp UI borders.

// Login page background
$login-page-bg: hsla(260, 73%, 47%, 0.45);
$hover-grey: #9e9e9e33;

// fonts
$poppins-regular: 'Poppins-Regular';
$poppins-medium: 'Poppins-Medium';
$poppins-bold: 'Poppins-Bold';

// fonts Red Hat
$red-hat: 'Red Hat Display';

// Screen width breakpoints for media query
// TODO: Remove when Media mixin is replaced with Interview Tools Responsive mixin
$breakpoints: (
  mobile: 480px,
  tablet: 768px,
  medium-screen: 992px,
  desktop-sm: 1024px,
  desktop-md: 1440px,
  desktop-lg: 1800px,
  desktop-xl: 2500px,
  large-screen: 1200px,
);

// Setup Modal Revamp
$spacer: 1;

// Urls
$theme-toggle-light: url('../../../assets/images/theme_toggle_light.svg');
$theme-toggle-dark: url('../../../assets/images/theme_toggle_dark.svg');

// Variables for focus overlay of modal
$border-thickness: 5px;
$border-width: 40px;
$gradient: #0000 90deg, #000 0;
$background: $border-width $border-width no-repeat;
$mask: conic-gradient(from 90deg at top $border-thickness left $border-thickness, $gradient) 0 0 / $background,
  conic-gradient(from 180deg at top $border-thickness right $border-thickness, $gradient) 100% 0 / $background,
  conic-gradient(from 0deg at bottom $border-thickness left $border-thickness, $gradient) 0 100% / $background,
  conic-gradient(from -90deg at bottom $border-thickness right $border-thickness, $gradient) 100% 100% / $background;

// Aspect-ratio
$aspect-ratio-4-3: 1.33;

// Header height
$header-height: 75px;
$header-height-sm: 50px;

// Assessment question drawer
$drawer-width: 290px;
$drawer-collapsed-width: 60px;
$drawer-animation-duration: 300ms;

// Markdown colors
$code-background: #f6f8fa;
$table-border: #d0d7de;
